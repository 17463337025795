const env = {
  SERVER: 'https://api.nswalletpass.com',
  // SERVER: 'https://api.nswalletpass.com',
  API_URL: 'https://api.nswalletpass.com/api',
  // API_URL: 'https://api.nswalletpass.com/api',
  GHL: {
    V2_BASE_URL: 'https://api.msgsndr.com',
    //dev-L-Location
    CLIENT_ID: '6657377ac7237b41dff768a2-lwrx1jl8',
    CLIENT_SECRET: 'f56c407e-aba2-461f-9e3a-3f290d16bf6c',
    SCOPE:
      'businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write campaigns.readonly conversations.readonly conversations.write conversations/message.readonly conversations/message.write contacts.readonly contacts.write forms.readonly forms.write links.readonly links.write locations.readonly locations/customValues.readonly locations/customValues.write locations/customFields.readonly locations/customFields.write locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write medias.readonly locations/templates.readonly medias.write opportunities.readonly opportunities.write surveys.readonly users.readonly users.write workflows.readonly',
    REDIRECT: 'https://dev.app.walletcampaigns.com/integrations/ghl_location',
  },
  STRIPE: {
    PUBLIC_KEY: '', // walletcampaigns
    CLIENT_ID: '', // walletcampaigns
  },
  G_MAP_API_KEY: 'AIzaSyCxoW20AL3qJhDKuo25Celg9X_HayFrIHg',
}

export default env
