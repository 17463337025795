import React, { lazy } from "react";
import ComponentLoader from "./component-loader";
import AdminLayout from "screens/admin/AdminLayout";
import { Navigate } from "react-router-dom";
import { useLocationInfo } from "context/location";

const SettingCustomersField = ComponentLoader(
  lazy(() => import("screens/admin/Customers/ghl-fields-map"))
);

// DASHBOARD
const Dashboard = ComponentLoader(
  lazy(() => import("screens/admin/Dashboard/dashboard"))
);
const Home = ComponentLoader(lazy(() => import("screens/admin/home/home")));

// CAMPAIGNS
const CampaignModule = ComponentLoader(
  lazy(() => import("screens/admin/Campaigns/"))
);
const BroadCastModule = ComponentLoader(
  lazy(() => import("screens/admin/BroadCast/"))
);
const CreateCampaign = ComponentLoader(
  lazy(() => import("screens/admin/Campaigns/create/create-campaign"))
);
const CampaignList = ComponentLoader(
  lazy(() => import("screens/admin/Campaigns/campaign-list"))
);
const CampaignInsight = ComponentLoader(
  lazy(() => import("screens/admin/Campaigns/insight/"))
);

//Reward
const RewardModule = ComponentLoader(
  lazy(() => import("screens/admin/Reward"))
);

// GROWTHTOOL
const GrowthToolModule = ComponentLoader(
  lazy(() => import("screens/admin/GrowthTool"))
);
const GrowthTool = ComponentLoader(
  lazy(() => import("screens/admin/GrowthTool/growthtool"))
);

// CUSTOMER
const Customers = ComponentLoader(
  lazy(() => import("screens/admin/Customers/customers"))
);
const Insight = ComponentLoader(
  lazy(() => import("screens/admin/Customers/view-insight"))
);
const Info = ComponentLoader(
  lazy(() => import("screens/admin/Customers/customer-info"))
);

// SCANNER
const ScannerModule = ComponentLoader(
  lazy(() => import("screens/admin/Scanner/"))
);
const Scanner = ComponentLoader(
  lazy(() => import("screens/admin/Scanner/scanners"))
);
const UsercodeTable = ComponentLoader(
  lazy(() => import("screens/admin/Scanner/usercode-Table"))
);

const Staff = ComponentLoader(
  lazy(() => import("screens/admin/Scanner/Staff"))
);
const ScannerLogs = ComponentLoader(
  lazy(() => import("screens/admin/Scanner/logs"))
);
// NOTIFICATION
const CreateBroadCast = ComponentLoader(
  lazy(() => import("screens/admin/BroadCast/create"))
);
const UpdateBroadCast = ComponentLoader(
  lazy(() => import("screens/admin/BroadCast/create"))
);
const ViewLogs = ComponentLoader(
  lazy(() => import("screens/admin/BroadCast/view-logs"))
);
const BroadCast = ComponentLoader(
  lazy(() => import("screens/admin/BroadCast/broadcast"))
);
const Notification = ComponentLoader(
  lazy(() => import("screens/admin/BroadCast/index"))
);
const BroadCastLogs = ComponentLoader(
  lazy(() => import("screens/admin/BroadCast/create"))
);
const PushAutomation = ComponentLoader(
  lazy(() => import("screens/admin/BroadCast/push-automation"))
);

// WEBHOOK
const WebhookModule = ComponentLoader(
  lazy(() => import("screens/admin/Webhook/"))
);
const Webhook = ComponentLoader(
  lazy(() => import("screens/admin/Webhook/webhook-list"))
);
const WebhookLogs = ComponentLoader(
  lazy(() => import("screens/admin/Webhook/view-logs"))
);
const CreateWebhook = ComponentLoader(
  lazy(() => import("screens/admin/Webhook/create/create-webhook"))
);
const UpdateWebhook = ComponentLoader(
  lazy(() => import("screens/admin/Webhook/create/create-webhook"))
);

// SETTINGS
const GeneralInfo = ComponentLoader(
  lazy(() => import("screens/admin/GeneralInfo/generalinfo"))
);
const Qrcode = ComponentLoader(
  lazy(() => import("screens/admin/GrowthTool/create/qrcode"))
);
const Formlink = ComponentLoader(
  lazy(() => import("screens/admin/GrowthTool/create/formlink"))
);
const Billings = ComponentLoader(
  lazy(() => import("screens/admin/Billings/billing"))
);
const Profile = ComponentLoader(
  lazy(() => import("screens/admin/Profile/profile"))
);

const GeoLocationModule = ComponentLoader(
  lazy(() => import("screens/admin/GeoLocation"))
);
const ApiKey = ComponentLoader(
  lazy(() => import("screens/admin/ApiKey/apikey"))
);
const Integration = ComponentLoader(
  lazy(() => import("screens/admin/Integration/integration"))
);
const IntegrateStripe = ComponentLoader(
  lazy(() => import("screens/public/stripe/stripe"))
);
const AgencyInfo = ComponentLoader(
  lazy(() => import("screens/admin/AgencyInfo/agencyinfo"))
);

const AdminRoutes = () => {
  const { isAccessable } = useLocationInfo();
  console.log("CAMAPIGN", isAccessable("campaign"));
  return [
    {
      path: "",
      element: <AdminLayout />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        isAccessable("campaign")
          ? {
              path: "campaigns",
              element: <CampaignModule />,
              children: [
                {
                  path: "",
                  index: true,
                  element: <CampaignList />,
                },
                {
                  path: "create",
                  element: <CreateCampaign />,
                },
                {
                  path: "update",
                  element: <CreateCampaign />,
                },
                {
                  path: "insight",
                  element: <CampaignInsight />,
                },
              ],
            }
          : {},
        {
          path: "growthtool",
          element: <GrowthToolModule />,
          children: [
            {
              path: "",
              index: true,
              element: <GrowthTool />,
            },

            {
              path: "formlink",
              element: <Formlink />,
            },
            {
              path: "qrcode",
              element: <Qrcode />,
            },
          ],
        },
        isAccessable("customer")
          ? {
              path: "customers",
              children: [
                {
                  path: "",
                  index: true,
                  element: <Customers />,
                },
                {
                  path: "view",
                  element: <Insight />,
                },
                {
                  path: "info",
                  element: <Info />,
                },
                {
                  path: "settings",
                  element: <SettingCustomersField />,
                },
              ],
            }
          : {},
        {
          path: "broadcast",
          element: <BroadCastModule />,
          children: [
            {
              path: "",
              index: true,
              element: <BroadCast />,
            },
            {
              path: "create-broadcast",
              element: <CreateBroadCast />,
            },
            {
              path: "update",
              element: <UpdateBroadCast />,
            },
            {
              path: "view-logs",
              element: <ViewLogs />,
            },
          ],
        },
        isAccessable("scanner")
          ? {
              path: "scanner",
              element: <ScannerModule />,
              children: [
                {
                  path: "",
                  element: <Scanner />,
                  index: true,
                },
                {
                  path: "staff",
                  element: <Staff />,
                },
                {
                  path: "logs",
                  element: <ScannerLogs />,
                },
                {
                  path: "usercode-table",
                  element: <UsercodeTable />,
                },
              ],
            }
          : {},

        // WEBHOOK
        {
          path: "webhook",
          element: <WebhookModule />,
          children: [
            {
              path: "",
              index: true,
              element: <Webhook />,
            },
            {
              path: "create-webhook",
              element: <CreateWebhook />,
            },
            {
              path: "update",
              element: <UpdateWebhook />,
            },
            {
              path: "view-logs",
              element: <WebhookLogs />,
            },
          ],
        },

        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "settings",
          children: [
            {
              path: "generalinfo",
              element: <GeneralInfo />,
            },
            {
              path: "billings",
              element: <Billings />,
            },
            {
              path: "geo-location",
              element: <GeoLocationModule />,
            },
            {
              path: "apikey",
              element: <ApiKey />,
            },
            {
              path: "integration",
              element: <Integration />,
            },
            {
              path: "agencyinfo",
              element: <AgencyInfo />,
            },
            {
              path: "",
              index: true,
              element: <Navigate replace to={"./generalinfo"} />,
            },
            {
              path: "*",
              element: <Navigate replace to={"./generalinfo"} />,
            },
          ],
        },

        {
          path: "",
          index: true,
          element: <Navigate replace to={"./dashboard"} />,
        },
        {
          path: "*",
          index: true,
          element: <Navigate replace to={"./dashboard"} />,
        },
      ],
    },
    {
      path: "home",
      element: <Home />,
    },
    {
      path: "integrations",
      children: [
        {
          path: "stripe",
          element: <IntegrateStripe />,
        },
      ],
    },
    {
      path: "",
      element: <Navigate replace to={"./"} />,
    },
    {
      path: "*",
      element: <Navigate replace to={"./"} />,
    },
  ];
};

export default AdminRoutes;
